<template>
  <div id="inspection-form">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>分配用户</span>
      </div>
      <div class="content">
        <div class="query-form">
          <!-- <el-form class="form" ref="form" :inline="true" :model="requestParam">

            <el-form-item label="用户名称：">
              <el-input v-model="requestParam.roleName" placeholder="请输入用户名称" clearable>
              </el-input>
            </el-form-item>
          </el-form> -->
          <div class="query-button">
            <el-button type="primary" icon="el-icon-plus" @click="createInspection">添加用户</el-button>
            <el-button type="primary" :disabled="multipleSelection.length ==0" icon="el-icon-close" @click="deleteAuthor">批量取消授权</el-button>
            <el-button type="primary" icon="el-icon-close" @click="goRoleAdmin">返回</el-button>
          </div>
        </div>

        <el-table v-loading='dataLoading' element-loading-text="加载中" element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.3)" :cell-style="{ color: '#AED6FF', fontSize: '12px' }"
          :header-cell-style="{ color: '#e8f4ff', fontSize: '14px' }" class="table" :data="tableData" default-expand-all
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" row-key="id"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column align="center" label="序号" type="index" :index="getIndex" width="60px">
          </el-table-column>
          <el-table-column align="center" prop="userName" label="用户名称" width="300px">
          </el-table-column>
          <el-table-column align="center" prop="passWord" label="密码">
          </el-table-column>
          <!-- <el-table-column align="center" prop="createBy" label="创建人">
          </el-table-column> -->
          <el-table-column align="center" prop="createTime" label="创建时间">
          </el-table-column>
          <el-table-column align="center" prop="data" fixed="right" label="操作" width="180px">
            <template slot-scope="scope">
              <!-- <el-button @click="editDialog(scope.row)" type="text">编辑</el-button><span style="color: #409EFF;">|</span>
              <el-button @click="deleteDialog(scope.row)" type="text">删除</el-button> -->
              <el-button @click="deleteDialog(scope.row)" type="text">取消授权</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- <div class="pagination">
          <div class="btn" @click="startHome">首页</div>
          <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
            @size-change="changeSize" @current-change="changeCurrent">
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input v-model.number="inputValue" size="mini" maxlength="2" class="pagination-input"
              @change="inputValueChange"></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirm">确定</button>
          </div>
        </div> -->
      </div>
    </div>
    <el-dialog @close="closeDialog('form')" title="选择用户" :visible.sync="dialogVisible"
      width="751px">
      <div class="dialog-content">
        <el-table :cell-style="{ color: '#AED6FF', fontSize: '12px' }"
          :header-cell-style="{ color: '#e8f4ff', fontSize: '14px' }" class="table" :data="dialogTable" default-expand-all
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" row-key="id"
          ref="multipleTable"
          @selection-change="handleSelectionChangeDialog">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column align="center" label="序号" type="index" :index="getIndex" width="60px">
          </el-table-column>
          <el-table-column align="center" prop="userName" label="用户名称" >
          </el-table-column>

          <!-- <el-table-column align="center" prop="createBy" label="创建人">
          </el-table-column> -->
          <el-table-column align="center" prop="createTime" label="创建时间">
          </el-table-column>
          </el-table>

        
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addDialogVisible">确 定</el-button>
      </div>
    </el-dialog>


    <el-dialog title="取消授权" :visible.sync="deleteVisible" width="436px">
      <div class="delete">确认要取消授权所选中的数据吗?</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteInspectionForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import { inspectionFormApi } from "@/api/inspectionForm.js";
import { getTableTree, getUserIdList, roleRemove,getUnBoundUserList,assignUser,roleByUserId } from "@/api/eios.js"

export default {
  name: "",
  data() {
    return {
      dataLoading: false,
      tableData: [],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        roleName: "",
      },
      form: {
        roleName: '',
        roleCode: '',
        enableFlage: '',
        menuIds: []

      },
      rules: {
        roleName: [
          { required: true, message: '请输入菜单名称', trigger: 'blur' },
        ],
        // provinceId: [
        //   { required: true, message: '请选择省', trigger: 'change' }
        // ],
      },
      total: 0,
      inputValue: "",
      deleteVisible: false,
      dialogVisible: false,
      dialogVisible2: false,
      id: "",
      dialogTotalType: 0,
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'menuName',
        id: 'id'
      },
      expandAll: false,
      checked: false,
      allChecked: false,
      roleId: '',
      multipleSelection: [],
      multipleSelectionDialog:[],
      dialogTable:[],
      dialogType:0,
    };
  },
  created() {
    this.$store.commit('increment', 'Eios控制面板')
    this.$store.commit('selectChild', '角色管理')
    this.dataLoading = false
    this.roleId = this.$route.query.roleId
    console.log(this.roleId);
    this.queryOperation()
    this.getDialogList()

  },


  methods: {
    getDialogList(){
      getUnBoundUserList().then(res=>{
        this.dialogTable=res.data
      }) 
    },
    //弹窗选中时
    handleSelectionChangeDialog(val){
      this.multipleSelectionDialog=val.map(el=>el.id)
      console.log(this.multipleSelectionDialog);
    },
    //列表选中时
    handleSelectionChange(val) {
      console.log("111111,", val);
      this.multipleSelection = val.map(el=>el.id)
    },
    isOverflow(content) {
      // const lineHeight = 16;
      const numLines = Math.ceil(content.length / 42); // 每行大概显示42个字符
      // console.log("====>",numLines);
      return numLines >= 3;
    },
    //序号
    getIndex(index) {
      return (this.requestParam.pageNum - 1) * this.requestParam.pageSize + index + 1;
    },
    closeDialog(form) {
      this.form = this.$options.data().form
      this.$refs.multipleTable.clearSelection();
      // this.$refs.tree.setCheckedKeys([]);
      // this.$refs[form].resetFields();
    },
    //新增
    addDialogVisible() {
      let obj={
        roleId:this.roleId,
        userIds:this.multipleSelectionDialog

      }
      assignUser(obj).then(res=>{
        console.log(res);
        if(res.code==200){
          this.dialogVisible=false
          this.queryOperation()
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
        }else{
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      })
    
    },
    //新增弹窗打开
    createInspection() {
      this.dialogVisible = true;
      this.getDialogList()
      this.dialogTotalType = 0
    },
    // editDialog(el) {
    //   this.id = el.id
    //   this.dialogVisible = true
    //   this.form = Object.assign({}, el);
    //   this.dialogTotalType = 1
    //   getTableTree(el.id).then(res => {
    //     this.form.menuIds = res.data.map(node => node.id)
    //   })
      // console.log( this.form);
      // this.$refs.tree.setCheckedKeys(this.form.menuIds);
    // },
    deleteDialog(item) {
      this.dialogType=1
      this.id = item.id;
      this.deleteVisible = true;
    },
    formQuery() {
      // this.requestParam.pageNum = 1;
      // this.multipleSelection
      
      
      this.queryOperation();
    },
    queryOperation() {
      this.dataLoading = true
      getUserIdList(this.roleId).then(res => {
        this.dataLoading = false
        if (res.code === 200) {
          this.tableData = res.data
          this.total = res.total
        }
      })


    },
    goRoleAdmin(){
      this.$router.push({
        name:'RoleAdmin',
      })
    },

    // //页数输入
    // inputValueChange() {
    //   const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
    //   if (this.inputValue < 1) {
    //     this.inputValue = 1;
    //   } else {
    //     this.inputValue =
    //       this.inputValue < lastPage ? this.inputValue : lastPage;
    //   }
    // },
    // //首页
    // homePage() {
    //   this.requestParam.pageNum = 1;
    //   this.queryOperation();
    // },
    // //尾页
    // tailPage() {
    //   const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
    //   this.requestParam.pageNum = lastPage;
    //   this.queryOperation();
    // },
    // confirm() {
    //   this.requestParam.pageNum = this.inputValue;
    //   this.queryOperation();
    // },
    // changeSize(pageSize) {
    //   this.requestParam.pageSize = pageSize;
    //   this.queryOperation();
    // },
    // changeCurrent(pageNum) {
    //   this.requestParam.pageNum = pageNum;
    //   this.queryOperation();
    // },
    // //删除
    deleteInspectionForm() {
      console.log(this.multipleSelection);
      let func = this.dialogType==0?roleRemove(this.multipleSelection):roleByUserId(this.id)
      func.then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.deleteVisible = false;
          this.queryOperation();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    deleteAuthor(){
      this.deleteVisible=true
      this.dialogType=0
    }

  },

};
</script>
<style scoped>
#inspection-form {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left top;
  background-size: cover;
  font-size: 16px;
}

.main {
  width: 100%;
  padding: 20px 26px 28px 25px;
  box-sizing: border-box;
}

.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title img {
  width: 20px;
  height: 20px;
}

.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}

/* 查询表单 */
.query-form {
  padding: 16px 20px;
  background-color: #061b45;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.query-button {
  display: flex;
  /* justify-content: space-between; */
}

.query-button ::v-deep .el-button {
  height: 35px;
  padding: 0 20px;
  background-color: #176ad2;
  text-align: center;
  line-height: 35px;
  font-size: 12px;
  color: #e8f4ff;
  border: 0;
}

.form ::v-deep .el-form-item {
  margin: 0 16px 12px 0;
  /* width: 100%; */
}

.form ::v-deep.el-form-item__label {
  width: 61px;
  padding-right: 0;
  color: #e8f4ff;
  font-size: 12px;
  text-align: left;
}

/* input */
.form ::v-deep .el-input .el-input__inner {
  height: 35px;
  width: 180px;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.form ::v-deep .el-input .el-input__inner {
  height: 35px;
  width: 180px;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

::v-deep .el-input .el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.form ::v-deep .el-input input::placeholder {
  font-size: 12px;
  color: #6180a8;
}

::v-deep .el-input input::placeholder {
  font-size: 12px;
  color: #6180a8;
}

::v-deep .el-textarea .el-textarea__inner::placeholder {
  font-size: 12px;
  color: #6180a8;
}

/* 下拉选择框 */
.el-select>>>.el-select-dropdown {
  background: #041c4c;
  border: 1px solid #09295b;
  color: #aed6ff;
}

::v-deep .el-select-dropdown__item.hover {
  background: #041c4c;
  color: #fff;
}

::v-deep .el-select-dropdown__item {
  color: #aed6ff;
}

::v-deep .el-select-dropdown__item.selected {
  color: #fff;
}

.el-select ::placeholder {
  color: #6180a8;
}

.el-select {
  width: 100%;
}

/* 表格 */
/* 设置表头的背景颜色 */
.table ::v-deep thead th {
  background-color: #09224b;
  color: #e8f4ff;
  font-size: 14px;
}

.table ::v-deep tbody tr {
  font-size: 12px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}

::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}

::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}

.table ::v-deep .el-table__body tr:hover>td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table__fixed-right::before {
  height: 0px;
}

::v-deep .el-table__body tr.hover-row>td.el-table__cell {
  background-color: #081f42 !important;
}

/* 暂无数据 */
::v-deep .el-table__body-wrapper {
  background: #071734;
}

::v-deep .el-table__empty-text {
  color: aliceblue;
  line-height: 150px;
}

.response-message {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.operate span {
  margin: 0 3px;
}

.tooltip {
  text-align: center;
}

/* 分页 */
.pagination {
  margin-top: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination ::v-deep .el-pagination {
  padding-left: 0;
}

.home-page,
.tail-page {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #92b5da;
  text-align: center;
  line-height: 30px;
  margin-right: 5px;
}

.home-page:hover,
.tail-page:hover {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}

.home-page:checked,
.tail-page:checked {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}

.el-pagination>>>.btn-prev,
.el-pagination>>>.btn-next {
  width: 60px;
  height: 30px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  margin-right: 5px;
  line-height: 30px;
  padding: 0;
  text-align: center;
}

.el-pagination>>>.el-pager li {
  width: 35px;
  height: 30px;
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.jump-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-input {
  height: 30px;
  width: 35px;
  margin: 0 10px;
}

.pagination-input>>>.el-input__inner {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
  padding: 0;
  text-align: center;
  color: #92b5da;
}

.pagination ::v-deep.el-select .el-input__inner {
  height: 30px;

  color: #92b5da;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
}

.pagination ::v-deep .el-pagination__sizes {
  margin-right: 0;
}

.jump-pagination .text {
  color: #92b5da;
  line-height: 30px;
}

.confirm {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid rgba(228, 228, 228, 0);
  background-color: #176ad2;
  font-family: "微软雅黑", sans-serif;
  color: #d7d7d7;
  text-align: center;
  line-height: 30px;
  padding: 0;
  margin-left: 5px;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #071734;
}

::v-deep .el-dialog .el-dialog__header {
  background-color: #05153a;
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #c9d4f1;
}

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__footer {
  padding-top: 0;
}

.form-title {
  font-family: "Arial Bold", "Arial Normal", "Arial", sans-serif;
  font-weight: 700;
  color: #c9d4f1;
  font-size: 16px;
  margin-bottom: 16px;
}

.dialog-content {
  margin: 18px 50px 20px 47px;

}

.details-form ::v-deep.el-form-item__label {
  width: 69px;
  color: #c9d4f1;
  font-size: 12px;
  text-align: right;
  margin-right: 28px;
}

.details-form ::v-deep .el-input .el-input__inner {
  width: 447px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}

.details-form ::v-deep .el-textarea .el-textarea__inner {
  width: 447px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}

.interval {
  width: 654px;
  height: 1px;
  background-color: #06224e;
  margin-bottom: 16px;
}

.details-form .default-inspection ::v-deep .el-input .el-input__inner {
  width: 651px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}

.details-form .selected-inspection::v-deep .el-input .el-input__inner {
  width: 651px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: #041c4c;
}

.default-inspection ::v-deep .el-select-dropdown__item {
  height: 35px;
  background-color: rgba(255, 255, 255, 0);
  border-bottom: 1px solid #06224e;
  box-sizing: border-box;
  font-size: 12px;
  color: #aed6ff;
  font-weight: 300;
}

.details-form ::v-deep .el-select-dropdown__item {

  display: flex;
  justify-content: space-between;
}

::v-deep .el-popper[x-placement^="bottom"] .popper__arrow::after {
  top: 0;
  border-bottom-color: #041c4c;
}

.delete {
  font-size: 16px;
  font-weight: 700;
  color: #c9d4f1;
  margin: 42px 0 64px 39px;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialog-footer button {
  width: 106px;
  height: 36px;
  background: #2142a1;
  border: 0;
  color: #ffffff;
  font-size: 13px;
}

.tooltip {
  max-width: 600px;
  /* 设置最大宽度 */
  line-height: 20px;
}


::v-deep .el-icon-arrow-right:before {
  color: #aed6ff;
  font-size: 16px;
}

::v-deep .el-textarea {
  width: 100%;
}

.place {
  width: 100%;
}

.myDialogForm>>>.el-form-item__label {
  color: #e8f4ff;
}

.myFormItem>>>.el-input__inner {

  background-color: #071734;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;

}

.myFormItem>>>.el-textarea__inner {

  background-color: #071734;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;

}

/* select选择器 */
.el-select>>>.el-input__inner {
  background: #071734;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

/* tree
   */

::v-deep .el-tree {
  background: #071734;
  color: #6180a8;
}

::v-deep .el-tree-node {
  background: #071734 !important;
}

::v-deep .is-current {
  background: #071734 !important;
  /* color: #FFC74A; */
}

::v-deep .is-checked {
  background: #071734 !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #071734;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:focus {
  background: #071734 !important;
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #071734;
}

::v-deep .el-tree-node {
  background-color: #071734;

}

::v-deep .el-tree-node:focus>.el-tree-node__content {
  background-color: #071734;

}

.treeBody {
  border: 1px solid #09295b;
}

.treeTitle {
  display: flex;
}

.titleChecked {
  margin: 0 10px;
  color: #aed6ff;
}

::v-deep .el-checkbox__label {
  color: #6180a8;

}</style>

